import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Constants } from '../../lib/const/constants';
import { PrimaryButton } from '../../components/button';
import { useLocation } from 'react-router-dom';
import { ToastContainer,toast } from "react-toastify";
import AddFundsDialog from './Components/AddFundsDialog';

function ListUsers() {
    // const navigate = useNavigate();
    const location = useLocation();
    const paramsdata = location.state;
    const account_id = paramsdata.account_id;
    const [loading, setLoading] = useState(false);
    const [memberships, setmemberships] = useState([]);
    const [subaccounts, setSubaccounts] = useState([]);
    const [accountNumbers, setAccountNumbers] = useState([]);
    const [url, setUrl] = useState();
    const [showTab, setShowTab] = useState();
    const [kycLoading, setkycLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [membershipId, setMembershipId] = useState();
    const [workspaceName, setWorkspaceName] = useState();

    const openDialog = (membershipId, workspaceName) => { 
        setMembershipId(membershipId);
        setWorkspaceName(workspaceName);
        setIsOpen(true);
    };

    const getData = async () => {
        setLoading(true)
        var url;
        if(Constants.ENVIRONMENT=='uat')
        url = Constants.BASE_URL + "API-FX-216-GET-VIRTUAL-ACCOUNT?account_id=019ac802-fe8d-43ea-9690-3b1d02887dd5";
        else
        url = Constants.BASE_URL + "API-FX-216-GET-VIRTUAL-ACCOUNT?account_id="+account_id;
        await axios.get(url, {
            headers: {
                fx_key: Constants.SUBSCRIPTION_KEY
            }
        }).then(response => {
            setAccountNumbers(response.data.data.original.accountNumber);
            setmemberships(response.data.data.original.workspace)
            setSubaccounts(response.data.data.original.subaccounts)
            setLoading(false)
        }).catch(error => {
            console.log("workspaces" + error.response);
            setLoading(false)
        })

    }
    const generateKyc = async (mobile, index) => {
        if (mobile == null || mobile == "") {
            toast.error("Please enter mobile number.");
        }
        else {
            setkycLoading(true)
            setUrl();
            await axios.post(Constants.BASE_URL + 'API-FX-187-SUMSUB-URL', {
                "user_id": mobile
            }, {
                headers: {
                    fx_key: Constants.SUBSCRIPTION_KEY
                }
            }).then(resp => {
                setUrl(resp.data.url)
                setShowTab(index);
                setkycLoading(false)
            }).catch(err => {
                toast.error(err.response.data);
                setkycLoading(false)
            })
        }
    }
    const copyToClipBoard = async () => {
        navigator.clipboard.writeText(url)
        toast.success("Copied")
    }
    useEffect(() => {
        getData()
    }, [])

    return (
        <div className="my-2 flex">
            <ToastContainer />
            <div className='w-full'>
                {!loading ?
                    <table cellPadding={10} cellSpacing={10} className='w-full bg-[#ffffff]' >
                        {Object.values(memberships).map((workspace, index) => {
                            return (
                                <>
                                    {index == 0 &&
                                        <thead className="border-b-2 border-[#386a2d]">
                                            <tr>
                                                <th scope="col" className="px-6 py-6">Id</th>
                                                <th scope="col" className="px-6 py-6">Name</th>
                                                <th scope="col" className="px-6 py-6">Email</th>
                                                <th scope="col" className="px-6 py-6">Phone</th>
                                                <th scope="col" className="px-6 py-6">Type</th>
                                                <th scope="col" className="px-6 py-6">Balance</th>
                                                <th scope="col" className="px-6 py-6">Account Details</th>
                                                <th scope="col" className="px-6 py-6">KYC</th>
                                                <th scope="col" className="px-6 py-6">Add Funds</th>
                                            </tr>
                                        </thead>}
                                    {workspace.length > 0 &&
                                        <>
                                            <tr key={Object.keys(memberships)[index]} className="border-b-2 border-[#386a2d]">
                                                <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{workspace[0].id}</th>
                                                <td className="px-6 py-6">{workspace[0].name}</td>
                                                <td className="px-6 py-6">{workspace[0].email}</td>
                                                <td className="px-6 py-6">{workspace[0].phone}</td>
                                                <td className="px-6 py-6">{workspace[0].type}</td>
                                                <td className="px-6 py-6">{(Object.values(subaccounts)[index])}</td>
                                                <td className="px-6 py-6">
                                                    <b>IBAN</b>: {(Object.values(accountNumbers)[index])}<br />
                                                    <b>Account No</b>: {(Object.values(accountNumbers)[index]).slice(-8)}<br />
                                                    <b>Sort Code</b>: {((Object.values(accountNumbers)[index]).slice(0,-8)).slice(-6)}
                                                    </td>
                                                <td className="px-6 py-6">
                                                    <PrimaryButton
                                                        label={'Generate KYC'}
                                                        style={{ width: "100%" }}
                                                        onClick={() => generateKyc(workspace[0].phone, index)}
                                                        loading={kycLoading}
                                                    />
                                                    {url && showTab == index &&
                                                        <div className='my-3 flex' id={'url' + index}>


                                                            <>
                                                                <input
                                                                    type='text'
                                                                    disabled={true}
                                                                    required={true}
                                                                    placeholder={'KYC URL'}
                                                                    value={url}
                                                                    className="pl-8 bg-[#f0f0f0] w-1/2 p-2 outline-none rounded-2xl text-[#386a2d] placeholder:text-sm placeholder:text-slate-700"
                                                                />

                                                                <PrimaryButton
                                                                    label={'Copy'}
                                                                    style={{ width: "30%" }}
                                                                    onClick={copyToClipBoard}
                                                                />
                                                            </>

                                                        </div>
                                                    }
                                                </td>
                                                <td className="px-6 py-6">
                                                    <button onClick={() => openDialog(Object.keys(memberships)[index], workspace[0].name)}
                                                            className="btn-primary">
                                                        Add Funds
                                                    </button>

                                                    <AddFundsDialog
                                                        isOpen={isOpen}
                                                        onClose={() => setIsOpen(false)}
                                                        selectedWorkspace={workspaceName}
                                                        type={workspace[0].type}
                                                        creditor={membershipId}
                                                         debitor={Constants.ENVIRONMENT=='uat' ? "019ac802-fe8d-43ea-9690-3b1d02887dd5":account_id}
                                                    />
                                                </td>
                                            </tr>
                                        </>
                                    }
                                </>
                            )
                        })
                        }
                    </table>
                    :
                    <div className='text-bold'>loading...</div>
                }

            </div>
        </div>
    );
}

export default ListUsers;